import React, { useEffect, useState } from 'react'
import { Footer } from './MyComponents/Footer'
import { Header } from './MyComponents/Header'
import doticon from './images/doticon.png'
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import axios from 'axios'

var decodeHTML = function (html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };
  
  function createMarkup(content) {
      return {__html: decodeHTML(content)};
  }
  
export const Career = () => {

    const [pagedata, setPagedata] = useState([]);

    useEffect(() => {
        let mounted = true;
        const getCareerData = async () => {
            const response = await axios.get('https://fortunaholdings.net/api/career.php');
            if(mounted){
                setPagedata(response.data);
            }
        }

        getCareerData();
        return () => {
            mounted = false;
          };
    }, []);

    

    console.log(pagedata);

    if (pagedata.length===0) {
        console.log('no data');
        return null;
    }


    return (
        <>
            <div className='innerhead'>
                <Header />
            </div>
            {pagedata.career_content ?
                <section id='banner'>
                    <div className='webbanner'>
                        <img src={pagedata.career_content.banner} alt='' />
                        <div className='icon icon01'>
                            <span className='circle right-align slow-move-anticlockwise'></span>
                            <img src={doticon} alt='' />
                        </div>
                    </div>
                </section> : null
            }
            <section id='esg'>
                <div className='esg'>
                    <div className="icon02"><span className="circle slow-move-anticlockwise"></span></div>
                    <div className='container'>
                        <div className='web-container'>
                            <div className='csgstn'>
                                <div className='heading' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                                    <h2 className='title'>Career</h2>
                                    <div dangerouslySetInnerHTML={{ __html: pagedata.career_content ? pagedata.career_content.descp : null }} data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true" />
                                </div>
                                {pagedata.career_content ? 
                                <span className='careerlink' data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="750" data-aos-once="true"><a href={`mailto: ${pagedata.career_content.email}`}>{pagedata.career_content.email_text}</a></span> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            { pagedata.jobs ?
            <section id='currentopening'>
                <div className='currentopening'>
                    <div className='container'>
                        <div className='web-container'>
                            <div className='csgstn'>
                                <div className='heading' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                                    <h2 className='title'>Current Openings</h2>
                                </div>
                                
                                <div className="Accordians" data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="750" data-aos-once="true" >
                                    <Accordion atomic={true}>
                                        {pagedata.jobs.map(job => {
                                        return <AccordionItem key={job.id} title={job.heading}>
                                            <p dangerouslySetInnerHTML={createMarkup(job.text)}></p>
                                        </AccordionItem>
                                        })}


                                    </Accordion>
                                </div>
                                
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            : null
                                }
            <Footer contact={pagedata.contact} branches={pagedata.branches} />
        </>
    )
}
export default Career;