import React from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import Error from "./pages/Error";
import { Home } from "./Home";
import { About } from "./About"
import { Investment } from "./Investment"
import { People } from "./People"
import { Esg } from "./Esg"
import { Career } from "./Career"
import { Contact } from "./Contact"
import ScrollToTop from "./ScrollToTop";

const App = () => {
  return (
    <>
    <Router>
      <ScrollToTop/>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/about" component={About}></Route>
        <Route exact path="/investment" component={Investment}></Route>
        <Route exact path="/people" component={People}></Route>
        <Route exact path="/esg" component={Esg}></Route>
        <Route exact path="/career" component={Career}></Route>
        <Route exact path="/contact" component={Contact}></Route>
        <Route component={Error}></Route>
      </Switch>
      </Router>
    </>
  );
};

export default App;
