import React, { useEffect, useState } from 'react'
import { Footer } from './MyComponents/Footer'
import { Header } from './MyComponents/Header'
import doticon from './images/doticon.png'
import axios from 'axios'

export const Esg = () => {

    const [pagedata, setPagedata] = useState([]);

    useEffect(() => {
        let mounted = true;
        const getEsgData = async () => {
            let mounted = true;
            const response = await axios.get('https://fortunaholdings.net/api/esg.php');
            if(mounted){
                setPagedata(response.data);
            }
        }

      getEsgData();
      return () => {
        mounted = false;
      };
    }, []);
    
    console.log(pagedata);

    if (pagedata.length===0) {
        console.log('no data');
        return null;
    }


    return (
        <>
           <div className='innerhead'>
        <Header/>
        </div>
        {
            pagedata.esg_content ?
        
        <section id='banner'>
                <div className='webbanner'>
                    <img src={pagedata.esg_content.banner} alt=''/>
                    <div className='icon icon01'>
                  <span className='circle right-align slow-move-anticlockwise'></span>
                  <img src={doticon} alt=''/>
              </div>
                </div>
            </section>
            : null
        }

        {pagedata.esgs ? 
            <section id='esg'>
                <div className='esg'>
                <div className="icon02"><span className="circle slow-move-anticlockwise"></span></div>
                    <div className='container'>
                        <div className='web-container'>
                        <div className='csgstn'>
                            <div className='heading'>
                                <h2 className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">ESG</h2>
                                <div dangerouslySetInnerHTML={{__html: pagedata.esg_content ? pagedata.esg_content.descp : null}} data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true" />
                            </div>
                        </div>
                        <div className='esfstn'>
                            {pagedata.esgs.map(esg => {
                              return  <div className='esfbox' key={esg.id} data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                                <div className='esfimg'>
                                    <img src={esg.img} alt={esg.heading} />
                                </div>
                                <div className='esgcont'>
                                    <h5>{esg.heading}</h5>
                                    <p>{esg.text}</p>
                                </div>
                            </div>
                            })}
                            
                            
                           
                            
              <span className='border1 slow-move-anticlockwise'></span>
              <span className='border1 slow-move-anticlockwise sec'></span>
                  <span className='border2 slow-move-anticlockwise'></span>
                    <span className='border3 slow-move-anticlockwise'></span>
                    <span className='borderfil4 slow-move-anticlockwise'></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        : null
        }
        <Footer contact={pagedata.contact} branches={pagedata.branches} />            
        </>
    )
}
export default Esg;