import React, { useEffect } from 'react'
import logo from '../images/fortuna-logo.png'
import { Navbar } from './Navbar'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Aos from "aos";
import "aos/dist/aos.css"
import { Helmet } from "react-helmet";
export const Header = () => {
    useEffect(() => {
        Aos.init({duration: 1000});
        window.addEventListener('scroll', changeBackground);             

        return () => {
          window.removeEventListener('scroll', changeBackground);
        }
       }, []);
       const [navbar,setNavbar] = useState(false);
       const ddd = window.location.href; console.log(ddd);
        const changeBackground = () => {
         if(window.scrollY >= 80) {
           setNavbar(true);
         } else {
           setNavbar(false);
         }
        };
      
    return (
        <>
         <div>
            <Helmet>
                <link rel="canonical" href={ddd} />
            </Helmet>
        </div>
          <header id='header' className={navbar ? 'navbar active' : 'navbar'}>
              <div className='header'>
                      <div className='container'>
                          <div className='headerstr'>
                              <div className='logo' data-aos="flip-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                                <Link to='/'>
                                  <img src={logo} alt='' />
                                  </Link>
                              </div>
                                <div className='navbar'>
                                  <Navbar />
                              </div>
                      </div>
                  </div>
              </div>
            </header>  
        </>
    )
}
export default Header;