import React from 'react'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import "aos/dist/aos.css"
export const Navbar = () => {
  const [isActive, setActive] = useState("false");
      
        const handleToggle = () => {
          setActive(!isActive);
        };
  return (
  <>
   <div className={isActive ? "isActive menus" : "isActive menushow"}>
            <button onClick={handleToggle} className="hemburgur">
            <span></span>
            <span></span>
            <span></span>
            </button>
            <ul className="display-flex" data-aos="fade-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
              <li className="submenu"><NavLink to="/about" exact activeClassName="active">About Us</NavLink></li>
              <li><NavLink to="/investment" exact activeClassName="active">Our Investments</NavLink></li>              
              <li><NavLink to="/people" exact activeClassName="active">Our People</NavLink></li>
              <li><NavLink to="/esg" exact activeClassName="active">ESG</NavLink></li>
              <li><NavLink to="/career" exact activeClassName="active">Career</NavLink></li>
              <li><NavLink to="/contact" exact activeClassName="active">Contact Us</NavLink></li>
            </ul>
              </div>
    </>
  )
}
export default Navbar;