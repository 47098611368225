import React from 'react'
import { NavLink } from 'react-router-dom'

export const Footer = (props) => {
    return (
        <>
              <footer>
          <div className="icon02"><span className="circle slow-move-anticlockwise"></span></div>
          <div className="icon"><span className="circle slow-move-anticlockwise"></span>
          </div>
              <div className='container'>
                  <div className='footer'>
                    <div className='footercontainer'>
                    {props.contact ? 
                      <div className='footerbox'  data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-once="true" data-aos-duration="600" dangerouslySetInnerHTML={{__html: props.contact.descp}} /> : null }
                      <div className='footerbox'  data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-once="true" data-aos-duration="600">
                          <ul>
                              <li><NavLink to="/about">ABOUT US</NavLink></li>
<li><NavLink to="/investment">OUR INVESTMENTS</NavLink></li>
<li><NavLink to="/people">OUR PEOPLE</NavLink></li>
<li><NavLink to="/esg">ESG</NavLink></li>
<li><NavLink to="/career">CAREER</NavLink></li>
<li><NavLink to="/contact">CONTACT US</NavLink></li>
                          </ul>
                      </div>
                      <div className='footerbox'  data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-once="true" data-aos-duration="600">
                          <h3>Your idea may interest us. <br />Let’s talk.</h3>
                          {props.contact ? 
                          <h4>{props.contact.company}</h4>
                          : null}
                          {
                          props.branches ?
                          props.branches.map((branch, index) => {
                           return <p key={`branch-${index}`}>
                            <span dangerouslySetInnerHTML={{__html: branch.addr}} />
                            <br />
                            {branch.tel ? 
                            <span dangerouslySetInnerHTML={{__html: branch.tel}}/>
                            //  <a href={`tel: ${branch.tel}`}>Tel: {branch.tel}</a>
                            : null
                            }
                            <br/><br/></p>
                          }) : null } 
                      </div>
                  </div>
                  </div>
                  <div className='copyright'>
                      <p>&copy; 2022 All rights reserved. | Fortuna Holdings</p>
                      <p><a href="https://triverseadvertising.com" target="_blank" rel="noreferrer noopener">Site : triverse</a></p>
                  </div>
              </div>
          </footer>
        </>
    )
}
export default Footer;
