import React from "react";
import Error404 from "../Error404";
import Footer from "../MyComponents/Footer";
import Header from "../MyComponents/Header";

const Error = () => {
  return (
    <>
      <Header/>
      <Error404 />
      <Footer/>
    </>
  );
};

export default Error;
