import React, { useEffect, useState } from 'react'
import { Footer } from './MyComponents/Footer'
import { Header } from './MyComponents/Header'
import doticon from './images/doticon.png';
import dummyimg from './images/dummyimg.jpg';
import axios from 'axios';

export const Contact = () => {

    const [pagedata, setPagedata] = useState([]);

    useEffect(() => {
        let mounted = true;
        const getContactData = async() => {
            let mounted = true;
            const response = await axios.get('https://fortunaholdings.net/api/contact-details.php');
            if(mounted){
                setPagedata(response.data);
            }
        }
      getContactData();
      return () => {
        mounted = false;
      };
    }, []);
    
    

    console.log(pagedata);

    if (pagedata.length===0) {
        console.log('no data');
        return null;
    }

    return (
        <>
        <div className='innerhead'>
        <Header/>
        </div>
        { pagedata.contact_content ?
        <section id='banner'>
                <div className='webbanner'>
                    <img src={pagedata.contact_content.banner} alt='' />
                    <div className='icon icon01'>
                  <span className='circle right-align slow-move-anticlockwise'></span>
                  <img src={doticon} alt='' />
              </div>
                </div>
            </section> : null
        }
        {pagedata.contact ?
            <section id='contactdetails'>
                <div className='contactdetils'>
                <div className="icon02"><span className="circle slow-move-anticlockwise"></span></div>
                <span className='border1 slow-move-anticlockwise'></span>
                <span className='border2 slow-move-anticlockwise'></span>
                <span className='borderfil4 slow-move-anticlockwise'></span>
                <div className='container'>
                    <div className='web-container'>
                        <div className='heading' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                            <h2 className='title'>Contact Us</h2>
                        </div>
                    <div className='contactdetails'>
                        <div className='contact50' data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                            <h4>{pagedata.contact.company}</h4>

                            {pagedata.branches.map((branch, index)=> {
                               return <p key={index}>
                                   <span dangerouslySetInnerHTML={{__html: branch.addr}}></span>
                                   <br />
                                {branch.tel ? 
                                <span dangerouslySetInnerHTML={{__html: branch.tel}}/>
                                //<a href={`tel: ${branch.tel}`}>Tel: {branch.tel}</a>
                                : null
                                }

                                </p>

                            })}
                        </div>
                        <div className='contact50' data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                            <img src={dummyimg} alt=''/>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
            </section> : null
}
<Footer contact={pagedata.contact} branches={pagedata.branches} />
            
        </>
    )
}
export default Contact;