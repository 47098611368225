import React, { useEffect, useState } from "react";
import { Footer } from './MyComponents/Footer'
import { Header } from './MyComponents/Header'
import doticon from './images/doticon.png';
import axios from "axios";

export const Investment = () => {
    const [isActive, setActive] = useState("false");

    const [pagedata, setPagedata] = useState([]);

    const handleToggle = () => {
        setActive(!isActive);
        let links = document.getElementsByClassName('investment-link');
  
        for (let index = 0; index < links.length; index++) {
            const element = links[index];
            element.classList.remove('d-none');
        }
  
        document.querySelector('.show-btn').remove();
      };

    useEffect(() => {
        let mounted = true;
        const loadData = async () => {
           const response = await axios.get('https://fortunaholdings.net/api/investment.php');
           if(mounted){
               setPagedata(response.data);
           }
        }

        loadData();

        return () => {
            mounted = false;
          }; 
    },[]);

    
 


    console.log(pagedata)

    if (pagedata.length===0) {
        console.log('no data');
        return null;
    }

    return (
        <>
       <div className='innerhead'>
        <Header/>
        </div>
        {pagedata.investment_content ? 
        <section id='banner'>
                <div className='webbanner'>
                    <img src={pagedata.investment_content.banner} alt=''/>
                    <div className='icon icon01'>
                  <span className='circle right-align slow-move-anticlockwise'></span>
                  <img src={doticon} alt=''/>
              </div>
                </div>
        </section> : null }
        
        { pagedata.investments ?


        <section id='investment'>
              <div className='exiteus'>
                  <div className='container'>
                      <div className='heading' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                          <h2 className='title'>Our Investments </h2>
                      </div>
                  </div>

                  
                  
                 { 
                                   
                    pagedata.investments.map((investment, index) => {
                    return  <div className='exitestn investments' key={investment.id}>
                      { (index%2===0) ? <div className="icon02"><span className="circle slow-move-anticlockwise"></span></div> : null }
                  <span className='border1 slow-move-anticlockwise'></span>
                  <span className='border2 slow-move-anticlockwise'></span>
                      <div className='exiteusimg' data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                          <img src={investment.img} alt='investment.title'/>
                      </div>
                      <div className='exiteuscont' >
                          <h3 data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">{investment.title}</h3>
                          <div className="exituscontinrr" dangerouslySetInnerHTML={{__html: investment.descp}} data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true" />
                          <div className={isActive ? "d-none" : null} dangerouslySetInnerHTML={{__html: investment.hidden_descp}} />
                          {investment.link ? 
                          <span className={`link investment-link ${ investment.hidden_descp ? 'd-none' : '' }`} data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true"><a href={investment.link} target="_blank">{investment.link_web}</a></span>
                          :
                          <span className={`link investment-link ${ investment.hidden_descp ? 'd-none' : '' }`} data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true"><a>Weblink Coming Soon</a></span>
                          }

                          {investment.hidden_descp ? 
                          <div className="btn plus show-btn" onClick={handleToggle}><span className="btnarrow"></span>+</div>
                        : null}
                      </div>
                      </div>
                      
                  }) 
                  

                   }
                 
                  
              </div>
          </section> : null
        }
        <Footer contact={pagedata.contact} branches={pagedata.branches} />            
        </>
    )
}
export default Investment;