import React, { useEffect, useState } from 'react'
import { Header } from './MyComponents/Header';
import doticon from './images/doticon.png';
import Footer from './MyComponents/Footer'
import axios from 'axios';

export const About = () => {

    const [pagedata, setPagedata] = useState([]);


    useEffect(() => {
        let mounted = true;
        const loadData = async () => {
           const response = await axios.get('https://fortunaholdings.net/api/about.php');
           if(mounted){
               setPagedata(response.data);
           }
        }

        loadData();

        return () => {
            mounted = false;
          };
    }, []);

    console.log(pagedata);

    if (pagedata.length===0) {
        console.log('no data');
        return null;
    }


    return (
        <>
            <div className='innerhead'>
                <Header />
            </div>
            {pagedata.about_content ?
                <section id='banner'>
                    <div className='webbanner'>
                        <img src={pagedata.about_content.banner} alt='' />
                        <h1 className='title' data-aos="flip-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">{pagedata.about_content.banner_heading}</h1>
                        <div className='icon icon01'>
                            <span className='circle right-align slow-move-anticlockwise'></span>
                            <img src={doticon} alt='' />
                        </div>
                    </div>
                </section>
                : null}
            {pagedata.about_content ?
                <section id='whoweare'>
                    <div className='whoweare'>
                        <div className="icon icon02"><span className="circle slow-move-anticlockwise"></span></div>
                        <span className="border1 slow-move-anticlockwise"></span>
                        <span className="border2 slow-move-anticlockwise"></span>
                        <div className='container'>
                            <div className='web-container'>
                                <div className='heading' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                                    <h2 className='title'>Who We are?</h2>
                                </div>
                                <div className='content50' data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true" dangerouslySetInnerHTML={{ __html: pagedata.about_content.long_descp }} />
                            </div>
                        </div>
                    </div>
                </section>
                : null}
            {pagedata.about_content ?
                <section id="bannerconts">
                    <div className='bannerconst'>
                        <div className='container'>
                            <div className='web-container'>
                                <div className='bannerconts' style={{ backgroundImage: `url(${pagedata.about_content.second_section_img})` }}>
                                    <h3 data-aos="fade-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">{pagedata.about_content.second_section_text}</h3>
                                </div>
                                <span className="border3 slow-move-anticlockwise"></span>
                                <span className="borderfil4 slow-move-anticlockwise"></span>
                            </div>
                        </div>
                    </div>
                </section>
                : null
            }
            {pagedata.about_content ?
                <section id='investment'>
                    <div className='investment'>
                        <div className='icon01'>
                            <span className='circle slow-move-anticlockwise'></span>
                        </div>
                        <div className='icon02'>
                            <span className='circle slow-move-anticlockwise'></span>
                        </div>
                        <span className='border3 slow-move-anticlockwise'></span>
                        <div className='investimg'>
                            <img src={pagedata.about_content.third_section_img} alt='' />
                        </div>
                        <div className='investcont'>
                            <h2 className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">{pagedata.about_content.third_section_heading}</h2>
                            <p data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">{pagedata.about_content.third_section_text}</p>
                            <span className='link' data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="700" data-aos-once="true"><a href={pagedata.about_content.third_section_link} target="_blank" rel='noreferrer'>{pagedata.about_content.third_section_link_text}</a></span>
                        </div>
                    </div>
                </section>
                : null
            }
            {pagedata.companies ?
            <section id='companies'>
                <div className='companies '>
                    <div className='container'>
                        <div className='heading text-center' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                            <h2 className='title'>Companies under the Fortuna umbrella</h2>
                        </div>
                        <div className='companiesstn black' data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                        {
                                pagedata.companies.map(company => {
                                    return (<div className='companiesbox' key={company.id}>
                                        <div className='circleimg'>
                                            <img src={company.img} alt='' />
                                        </div>
                                        <span dangerouslySetInnerHTML={{__html: company.company}} />
                                    </div>)
                                })
                            }
                            

                        </div>
                    </div>
                </div>
            </section>
            : null }
            { pagedata.about_content ? 
            <section id='sector'>
                <div className='companies aboutbanner2' style={{backgroundImage: `url(${pagedata.about_content.fixed_bg})`}}>
                    <div className='icon icon01'>
                        <span className='circle right-align slow-move-anticlockwise'></span>
                        <img src={doticon} alt='' />
                    </div>
                </div>
            </section>
            : null }
            {pagedata.sectors ?
            <section id='exciteus'>
                <div className='exiteus'>
                    <div className='container'>
                        <div className='heading' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                            <h2 className='title'>The sectors which excite us</h2>
                        </div>
                    </div>
                    {pagedata.sectors.map((sector, index) => {    
                    return <div className='exitestn' key={sector.id}>
                        { (index%2===0) ? <div className="icon02"><span className="circle slow-move-anticlockwise"></span></div> : null }
                        <span className='border1 slow-move-anticlockwise'></span>
                        <span className='border2 slow-move-anticlockwise'></span>
                        <div className='exiteusimg' data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                            <img src={sector.img} alt='' />
                        </div>
                        <div className='exiteuscont'>
                            <h3 data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">{sector.heading}</h3>
                            <p data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">{sector.text}</p>
                        </div>
                    </div>
                    })}
                </div>
            </section>
            : null }
            <Footer contact={pagedata.contact} branches={pagedata.branches} />
        </>
    )
}
export default About;