import React, { useEffect, useState } from 'react'
import { Footer } from './MyComponents/Footer'
import { Header } from './MyComponents/Header'
import doticon from './images/doticon.png'
import axios from 'axios'

export const People = () => {

    const [pagedata, setPagedata] = useState([]);
    

    useEffect(() => {
        let mounted = true;
        const loadData = async () => {
           const response = await axios.get('https://fortunaholdings.net/api/people.php');
           if(mounted){
               setPagedata(response.data);
           }

        }

        loadData();

      return () => {
        mounted = false;
      };
    }, []);

    console.log(pagedata);

    if (pagedata.length===0) {
        console.log('no data');
        return null;
    }
    
    return (
        <>
        <div className='innerhead'>
        <Header/>
        </div>
        {
            pagedata.people_content ?
            <section id='banner'>
                <div className='webbanner'>
                    <img src={pagedata.people_content.banner} alt=''/>
                    <div className='icon icon01'>
                  <span className='circle right-align slow-move-anticlockwise'></span>
                  <img src={doticon} alt=''/>
              </div>
                </div>
            </section> : null
        }
            <section id='people'>
                <div className='whoweare'>
                <div className="icon icon02"><span className="circle slow-move-anticlockwise"></span></div>               
                <span className="border1 slow-move-anticlockwise"></span>
                <span className="border2 slow-move-anticlockwise"></span>
                    <div className='container'>
                    {pagedata.peoples.coreteam ?
                    <div className='web-container'>
                        <div className='heading'  data-type="career">
                            <h2 className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">OUR PEOPLE</h2>
                            <div dangerouslySetInnerHTML={{__html: pagedata.people_content ? pagedata.people_content.descp : null}} data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true" />
                        </div>
                        <div className='people'>
                            {Object.keys(pagedata.peoples.coreteam).map(valueH => {
                                return  <div className='peoplebox' key={pagedata.peoples.coreteam[valueH].id} data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                                        <div className='peopleimg'><img src={pagedata.peoples.coreteam[valueH].img} alt={pagedata.peoples.coreteam[valueH].name} />
                                        </div>
                                        <div className='peoplecont'>
                                            <h4>{pagedata.peoples.coreteam[valueH].name} </h4>
                                            <span>{pagedata.peoples.coreteam[valueH].designation}</span>
                                        </div>
                                        <div className='descript' dangerouslySetInnerHTML={{__html: pagedata.peoples.coreteam[valueH].descp}} />
                                    </div>
                                })
                            }
                        </div>
                        </div>
                        : null}
                        {pagedata.peoples.hrconsultant ?
                        <div className='web-container'>
                        <div className='heading' data-type="career">
                            <h2 className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">HR Consultant</h2>
                        </div>
                        <div className='people'>
                            {Object.keys(pagedata.peoples.hrconsultant).map(valueH => {
                                return  <div className='peoplebox' key={pagedata.peoples.hrconsultant[valueH].id} data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                                        <div className='peopleimg'><img src={pagedata.peoples.hrconsultant[valueH].img} alt={pagedata.peoples.hrconsultant[valueH].name} />
                                        </div>
                                        <div className='peoplecont'>
                                            <h4>{pagedata.peoples.hrconsultant[valueH].name} </h4>
                                            <span>{pagedata.peoples.hrconsultant[valueH].designation}</span>
                                        </div>
                                        <div className='descript' dangerouslySetInnerHTML={{__html: pagedata.peoples.hrconsultant[valueH].descp}} />
                                    </div>
                                })
                            }
                        </div>
                        </div>
                        : null}
                        {pagedata.peoples.legaladvisor ?
                        <div className='web-container'>
                        <div className='heading' data-type="career">
                            <h2 className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">Legal Advisor</h2>
                        </div>
                        <div className='people'>
                            {Object.keys(pagedata.peoples.legaladvisor).map(valueH => {
                                return  <div className='peoplebox' key={pagedata.peoples.legaladvisor[valueH].id} data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                                        <div className='peopleimg'><img src={pagedata.peoples.legaladvisor[valueH].img} alt={pagedata.peoples.legaladvisor[valueH].name} />
                                        </div>
                                        <div className='peoplecont'>
                                            <h4>{pagedata.peoples.legaladvisor[valueH].name} </h4>
                                            <span>{pagedata.peoples.legaladvisor[valueH].designation}</span>
                                        </div>
                                        <div className='descript' dangerouslySetInnerHTML={{__html: pagedata.peoples.legaladvisor[valueH].descp}} />
                                    </div>
                                })
                            }
                        </div>
                        </div>
                        : null}
                        {pagedata.peoples.auditors ?
                        <div className='web-container'>
                        <div className='heading' data-type="career">
                            <h2 className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">Auditor</h2>
                        </div>
                        <div className='people'>
                            {Object.keys(pagedata.peoples.auditors).map(valueH => {
                                return  <div className='peoplebox' key={pagedata.peoples.auditors[valueH].id} data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                                        <div className='peopleimg'><img src={pagedata.peoples.auditors[valueH].img} alt={pagedata.peoples.auditors[valueH].name} />
                                        </div>
                                        <div className='peoplecont'>
                                            <h4>{pagedata.peoples.auditors[valueH].name} </h4>
                                            <span>{pagedata.peoples.auditors[valueH].designation}</span>
                                        </div>
                                        <div className='descript' dangerouslySetInnerHTML={{__html: pagedata.peoples.auditors[valueH].descp}} />
                                    </div>
                                })
                            }
                        </div>
                        </div>
                        : null}
                    </div>
                </div>
            </section>
            <Footer contact={pagedata.contact} branches={pagedata.branches} />
        </>
    )
}
export default People;
