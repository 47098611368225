import React, { useEffect, useState } from 'react'
import banner from './images/fortuna-holding-banner.jpg'
import mbanner from './images/mbanner.jpg'
import doticon from './images/doticon.png'
import arrow from './images/down_arrow.png'
import Header from './MyComponents/Header'
import Footer from './MyComponents/Footer'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-scroll'
import axios from 'axios'

export const Home = () => {

    const [pagedata, setPagedata] = useState([]);

    useEffect(() => {
        let mounted = true;

        const loadData = async () => {
            const response = await axios.get('https://fortunaholdings.net/api/home.php');
            if (mounted) {
                setPagedata(response.data);
            }
        }

        loadData();

        return () => {
            console.log('unmount');
            mounted = false;
        };
    }, []);

    console.log(pagedata);

    if (pagedata.length===0) {
        console.log('no data');
        return null;
    }

    return (
        <>
            <Header />
            <section id='banner'>
                <div className='banner'>
                    <img src={banner} alt='' className='desktop-show' />
                    <img src={mbanner} alt='' className='mobile-show' />
                    <div className='bannercont' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                        <h2>Single Family Office</h2>
                        <p>Dedicated to wealth generation,
                            consolidation and succession.</p>
                    </div>
                    <div className='icon icon01'>
                        <span className='circle right-align slow-move-anticlockwise'></span>
                        <img src={doticon} alt='' />
                    </div>
                    <Link to="about" smooth={true} duration={500} id="about">
                        <div className="downarrow">
                            <img src={arrow} alt="" className="animated bounce" />
                        </div>
                    </Link>
                </div>
            </section>
            {pagedata.about_content ?
                <section id='aboutus'>
                    <div className='aboutstn'>
                        <div className='icon icon02'>
                            <span className='circle slow-move-anticlockwise'></span>
                        </div>
                        <div className='aboutus'>
                            <div className='aboutimg' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                                <img src={pagedata.about_content.home_img} alt='' />
                            </div>
                            <div className='aboutcont' data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                                <span className='border1 slow-move-anticlockwise'></span>
                                <span className='border2 slow-move-anticlockwise'></span>
                                <h2 className='title'>About us</h2>
                                <p>{pagedata.about_content.descp}</p>
                                <div className='btn'>
                                    <NavLink to="/about"><span className='btnarrow'></span>Read more</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className='icon icon03'>
                            <span className='circle slow-move-anticlockwise'></span>
                            <span className='border3 slow-move-anticlockwise'></span>
                            <span className='borderfil4 slow-move-anticlockwise'></span>
                        </div>
                    </div>
                </section>
                : null}
            {pagedata.sectors ?
                <section id='sector'>
                    <div className='companies sector'>
                        <div className='container'>
                            <div className='heading' data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                                <h2 className='title'>The sectors which excite us</h2>
                            </div>
                            <div className='companiesstn' data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                                {pagedata.sectors.map(sector => {
                                    return <div className='companiesbox' key={sector.id}>
                                        <img src={sector.img} alt='' />
                                        <span dangerouslySetInnerHTML={{ __html: sector.heading }} />
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </section>
                : null}
            <Footer contact={pagedata.contact} branches={pagedata.branches} />
        </>
    )
}
export default Home;
